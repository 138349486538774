import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  headers = new HttpHeaders();
  constructor(private http: HttpClient, private cookieService: CookieService,
    private authService: AuthService) {
    this.headers.set('Content-Type', 'application/json');
  }

  getToken(type?: any) {
    let headers: HttpHeaders = new HttpHeaders();
    if (type === 'image') {
    } else {
      headers = headers.append('Content-Type', 'application/json');
    }

    if (this.cookieService.get('token')) {
      headers = headers.append('authorization', `Bearer ${this.cookieService.get('token')}`);
    }
    return headers;
  }

  postRequest(url: string, requestData: any, headerData?: any) {
    headerData = headerData ? headerData : this.getToken();
    const data = requestData;
    return this.http.post(`${environment.apiHost}${url}`, requestData, { headers: headerData })
      .pipe(catchError(err => this.errorHandler(err)));
  }

  putRequest(url: string, requestData: any, headerData?: any) {
    headerData = headerData ? headerData : this.getToken();
    const data = requestData

    return this.http.put(`${environment.apiHost}${url}`, data, { headers: headerData })
      .pipe(catchError(err => this.errorHandler(err)));
  }

  getRequest(url: string, headerData?: any) {
    headerData = headerData ? headerData : this.getToken();
    return this.http.get(`${environment.apiHost}${url}`, { headers: headerData })
      .pipe(catchError(err => this.errorHandler(err)));

  }

  deleteRequest(url: string, headerData?: any) {
    headerData = headerData ? headerData : this.getToken();
    return this.http.delete(`${environment.apiHost}${url}`, { headers: headerData })
      .pipe(catchError(err => this.errorHandler(err)));
  }

  errorHandler(error: HttpErrorResponse) {
    console.log('error', error);
    if (error.status === 401) {
      this.authService.logout();
      return;
    }
    return throwError(error);
  }

}
