export const CUSTOMERMENU = {
    PartList: {
        Menu: 'part_list',
    },
    MyOrders: {
        Menu: 'my_orders',
        SubMenu: {
            OrderList:'order_list',
            ReturnItem: 'return_item'
        }
    },
    Invoice: {
        Menu: 'invoice',
    },
    Profile: {
        Menu: 'profile',
        SubMenu: {
            ManageAccount: 'manage_account',
            Settings: 'settings',
            HelpSupport: 'help_and support',
        }
    }
}