import { EventEmitter, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AppGlobals } from '../app.globals';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class UserService extends HttpService {
  constructor(http: HttpClient, cookieService: CookieService, private appGlobals: AppGlobals,
    authService: AuthService) {
    super(http, cookieService, authService);
  }

  notificationReadEmitter: EventEmitter<any> = new EventEmitter();

  customerChangeEmitter: EventEmitter<any> = new EventEmitter();

  emitNotificationReadInputChange(val) {
    this.notificationReadEmitter.emit(val);
  }

  getInputChangeNotificationReadImageEmitter() {
    return this.notificationReadEmitter;
  }

  emitCustomerChange(val) {
    this.customerChangeEmitter.emit(val);
  }

  getCustomerChangeEmitter() {
    return this.customerChangeEmitter;
  }


  login(data: any) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.postRequest(this.appGlobals.urls.USER_LOGIN, data, headers);
  }

  getProfile(userId: any) {
    return this.getRequest(`${this.appGlobals.urls.GET_PROFILE}/${userId}`);
  }

  updateProfile(data: any, userId: any) {
    return this.putRequest(`${this.appGlobals.urls.PROFILE_UPDATE}/${userId}`, data);
  }

  changePwd(data: any) {
    return this.postRequest(`${this.appGlobals.urls.PASSWORD_UPDATE}`, data);
  }

  forgotPwd(data: any) {
    return this.postRequest(`${this.appGlobals.urls.FORGOT_PWD}`, data);
  }

  resetPwd(data: any) {
    return this.postRequest(`${this.appGlobals.urls.RESET_PWD}`, data);
  }

  verifyToken(token: any) {
    return this.getRequest(`${this.appGlobals.urls.VERIFY_USER_TOKEN}?token=${token}`);
  }

  verifyInvitation(code) {
    return this.postRequest(`${this.appGlobals.urls.VERIFY_USER_INVITATION}?invitationCode=${code}`, null);
  }

  inviteUsers(body) {
    return this.postRequest(`${this.appGlobals.urls.INVITE_USERS}`, body);
  }

  acceptInvitation(body) {
    return this.postRequest(`${this.appGlobals.urls.ACCEPT_INVITATION}`, body);
  }

  getInvites(customerId) {
    return this.getRequest(`${this.appGlobals.urls.GET_INVITES}/${customerId}`);
  }

  getCustomerUsers(customerId) {
    return this.getRequest(`${this.appGlobals.urls.GET_CUSTOMER_USERS}/${customerId}`);
  }

  updateCustomerUserType(id, isAdmin) {
    return this.postRequest(`${this.appGlobals.urls.POST_UPDATE_USER_TYPE}/${id}/${isAdmin}`, null);
  }

  updateUserImage(userId, imageGuid) {
    return this.postRequest(`${this.appGlobals.urls.UPDATE_PROFILE_IMAGE}/image/${userId}?imageUrl=${imageGuid}`, null);
  }

  getCustomer(customerId) {
    return this.getRequest(`${this.appGlobals.urls.GET_CUSTOMER}/${customerId}`);
  }

  postSupport(body) {
    return this.postRequest(`${this.appGlobals.urls.POST_SUPPORT}`, body);
  }

  postRequestAccess(body) {
    return this.postRequest(`${this.appGlobals.urls.POST_REQUEST_ACCESS}`, body);
  }

  getNotifications(userId, filter = 'all') {
    return this.getRequest(`${this.appGlobals.urls.GET_Notifications}?userId=${userId}&filter=${filter}&count=0`);
  }

  markNotificationAsRead(id) {
    return this.postRequest(`${this.appGlobals.urls.POST_NotificationMarkAsRead}/${id}`, null).toPromise().then(res => {
      return res;
    });
  }

  getPermissions(roleId) {
    return this.getRequest(`${this.appGlobals.urls.GET_USER_PERMISSION}?role=${roleId}&type=2`).toPromise().then(res => {
      return res;
    });
  }


  getMenuAccess(menu, subMenu, roleId) {
    // const promise = new Promise<void>((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve();
    //   }, 1500);
    // });
    // return promise.then(res => {
    //   return { isManage: true, isView: true };
    // });



    // const role = this.authService.getRoleId();
    return this.getRequest(`${this.appGlobals.urls.GET_USER_PERMISSION}?role=${roleId}&type=2`).toPromise()
      .then((response: any) => {
        const permissions = response.data;
        const menuPermissions = permissions.find(p => p.name === menu);
        if (subMenu === '') {
          return menuPermissions;
        }
        const subMenuPermission = permissions.find(p => p.parentId === menuPermissions.menuId && p.name === subMenu);
        return subMenuPermission;
      });
  }

  updateUserSettings(requestPayload) {
    return this.postRequest(`${this.appGlobals.urls.UPDATE_USER_SETTINGS}`, requestPayload);
  }

  getUserSettings(userId) {
    return this.getRequest(`${this.appGlobals.urls.GET_USER_SETTINGS}?userId=${userId}`);
  }

  getUserCustomers(userId) {
    return this.getRequest(`${this.appGlobals.urls.GET_USER_CUSTOMERS}?userId=${userId}`);
  }

}
