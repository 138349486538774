import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AppGlobals } from '../app.globals';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends HttpService {

  cartCount = new BehaviorSubject(null);
  orderStatusItems: any;
  constructor(http: HttpClient, cookieService: CookieService, authService: AuthService,
    private appGlobals: AppGlobals) {
    super(http, cookieService, authService);
  }

  getOrders(params: any) {
    let qs: any = '';

    qs = params['pageNumber'] ? qs + `pageNumber=${params['pageNumber']}` : qs;
    qs = params['pageSize'] ? qs + '&pageSize=' + params['pageSize'] : qs;
    qs = params['searchText'] ? qs + '&searchText=' + params['searchText'] : qs;
    qs = params['startDate'] ? qs + '&startDate=' + params['startDate'] : qs;
    qs = params['endDate'] ? qs + '&endDate=' + params['endDate'] : qs;

    let url = `${this.appGlobals.urls.GET_ORDERS}/${params['customerId']}?${qs}`;
    return this.getRequest(url);
  }

  getOrderDetails(orderId: any) {
    return this.getRequest(`${this.appGlobals.urls.ORDER_DETAILS}/${orderId}`);
  }

  addComment(comment: any, orderId: any) {
    return this.postRequest(`${this.appGlobals.urls.ADD_COMMENT}`, { orderId: orderId, comment: comment });
  }

  getOrderComments(orderId: any) {
    return this.getRequest(`${this.appGlobals.urls.GET_COMMENTS}/${orderId}`);
  }

  updateComment(data: any) {
    return this.putRequest(`${this.appGlobals.urls.UPDATE_COMMENT}`, data);
  }

  deleteComment(commentId: any) {
    return this.deleteRequest(`${this.appGlobals.urls.DELETE_COMMENT}/${commentId}`);
  }

  cancelOrder(data: any) {
    return this.postRequest(`${this.appGlobals.urls.CANCEL_ORDER}`, data);
  }

  addOrderReturn(data: any) {
    return this.postRequest(`${this.appGlobals.urls.ADD_RETURN}`, data)
  }

  setCartCount(count) {
    this.cartCount.next(count);
  }

  getCartCount() {
    return this.cartCount.asObservable();
  }

  setStatusItems(items) {
    this.orderStatusItems = items;
  }

  getStatusItems() {
    return this.orderStatusItems;
  }

  getOrderStatus() {
    return this.postRequest(`${this.appGlobals.urls.GET_ORDER_STATUS}`, {});
  }

  getAllReturnReasons() {
    return this.getRequest(`${this.appGlobals.urls.GET_ALL_RETURN_REASONS}`);
  }

  getAllReturnLabels(orderId) {
    return this.getRequest(`${this.appGlobals.urls.GET_RETURN_LABELS}/${orderId}`);
  }

  pingOrderViewer(orderId, userId) {
    return this.postRequest(`${this.appGlobals.urls.POST_PING_ORDERVIEW}/${userId}/${orderId}`, {});
  }

}
