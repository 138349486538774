import { Injectable } from '@angular/core';
// import AppConstants from './config/constants/app.constants.json';
// import PageConstants from './config/constants/page.constants.json';
@Injectable()
export class AppGlobals {
  // readonly appConstants: any = AppConstants;
  // readonly pageConstants: any = PageConstants;
  readonly urls = {
    USER_LOGIN: '/connect/token',
    GET_PARTS: '/api/Catalog/web/cstmr/GetAll',
    GET_PROFILE: '/api/Users/web/cstmr',
    ADD_TO_CART: '/api/Cart/web/cstmr/AddToCart',
    GET_CART: '/api/Cart/web/cstmr',
    UPDATE_CART_ITEM: '/api/Cart/web/cstmr/UpdateCartItem',
    REMOVE_CART_ITEM: '/api/Cart/web/cstmr/RemoveCartItem',
    REMOVE_ALL_CART: '/api/Cart/web/cstmr/RemoveAllCartItem',
    PLACE_ORDER: '/api/CustomerOrder/web/cstmr/PlaceOrder',
    GET_ORDERS: '/api/CustomerOrder/web/cstmr/getAll',
    ORDER_DETAILS: '/api/CustomerOrder/web/cstmr',
    ADD_COMMENT: '/api/CustomerOrder/web/cstmr/addComment',
    GET_COMMENTS: '/api/CustomerOrder/web/cstmr/getComments',
    UPDATE_COMMENT: '/api/CustomerOrder/web/cstmr/updateComment',
    DELETE_COMMENT: '/api/CustomerOrder/web/cstmr/deleteComment',
    CANCEL_ORDER: '/api/CustomerOrder/web/cstmr/cancelOrder',
    PROFILE_UPDATE: '/api/Users/web/cstmr/user',
    PASSWORD_UPDATE: '/api/Users/web/cstmr/changepassword',
    FORGOT_PWD: '/api/Users/web/cstmr/forgotpassword',
    RESET_PWD: '/api/Users/web/cstmr/resetpassword',
    VERIFY_USER_TOKEN: '/api/Users/web/cstmr/verifications',
    ADD_RETURN: '/api/CustomerReturn/web/cstmr/returnOrderItem',
    GET_ALL_RETURNS: '​/api​/CustomerReturn​/web​/cstmr​/getAllReturnItems​',
    GET_RETURN_DETAILS: '/api/CustomerReturn/web/cstmr/getReturnItemDetail',
    GET_ALL_RETURN_REASONS: '/api/CustomerReturn/web/cstmr/getAllReturnReasons',
    GET_ORDER_STATUS: '/api/CustomerOrder/web/cstmr/getStatus',
    GET_RETURN_LABELS: '/api/CustomerReturn/web/cstmr/getReturnLables',
    VERIFY_USER_INVITATION: '/api/Customer/web/cstmr/checkInvitationLinkValid',
    INVITE_USERS: '/api/Customer/web/cstmr/inviteUser',
    ACCEPT_INVITATION: '/api/Customer/web/cstmr/acceptInvitation',
    GET_INVITES: '/api/Customer/web/cstmr/invites',
    GET_CUSTOMER_USERS: '/api/Customer/web/cstmr/users',
    POST_UPDATE_USER_TYPE: '/api/Customer/web/cstmr/updateCustomerUserType',
    UPDATE_PROFILE_IMAGE: '/api/Users/web/cstmr',
    GET_CUSTOMER: '/api/Customer/web/cstmr',
    POST_SUPPORT: '/api/Support/web/admin',
    POST_REQUEST_ACCESS: '/api/Customer/web/cstmr/requestAccess',
    GET_Notifications: '/api/Notification/web/cstmr/getAll',
    POST_NotificationMarkAsRead: '/api/Notification/web/cstmr/markRead',
    GET_INVOICES: '/api/Invoice/web/cstmr/getAll',
    GET_INVOICE_TYPES: '/api/invoice/web/admin/getInvoiceTypes',
    GET_WAREHOUSE_DETAILS:'/api/Warehouse/web/admin/GetAll',
    GET_USER_PERMISSION:'/api/Permission/web/admin/getPermissionByRole',
    POST_PING_ORDERVIEW: '/api/CustomerOrder/web/cstmr/pingCustomerOrderView',
    UPDATE_USER_SETTINGS: '/api/Users/web/cstmr/updateUserSettings',
    GET_USER_SETTINGS: '/api/Users/web/cstmr/getUserSettings',
    GET_USER_CUSTOMERS: '/api/Users/web/cstmr/getUserCustomers',
  };

  readonly S3 = {
    Bucket_URL: 'https://rswms-profile-pictures.s3.us-east-2.amazonaws.com/'
  };
}
