import { Component, OnDestroy, OnInit, AfterViewInit, TemplateRef } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbToastrService, NbMenuItem, NbDialogService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../../shared/auth.service';
import { threadId } from 'worker_threads';
import { UserService } from '../../../shared/user.service';
import { Router } from '@angular/router';
import { OrderService } from '../../../shared/order.service';
import { AppGlobals } from '../../../app.globals';
import * as signalR from '@aspnet/signalr';
import { environment } from 'environments/environment';
import { ViewAllNotificationsComponent } from '../view-all-notifications/view-all-notifications.component';
import { CUSTOMERMENU } from 'app/config/constants/menu';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  showNotifications = false;
  cartCount = 0;
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  notificationsList = [];
  unreadCount = 0;



  currentTheme = 'default';
  custInfo: any;
  userMenu: NbMenuItem[] = [
    { title: 'Manage Account' },
    { title: 'Invite User' },
    { title: 'Settings' },
    { title: 'Help & Support' },
    { title: 'Sign out' }];
  notifications: NbMenuItem[] = [{
    icon: 'message',
    title: 'loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum'
  },
  {
    icon: 'message',
    title: 'loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum'
  },
  {
    icon: 'message',
    title: 'loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum loreum ipsum'
  }
  ];

  notificationItems: Array<{}>;
  userId = '';
  showAllNotification = false;
  parentPermission = [];
  childPermissions = [];

  private hubConnection: signalR.HubConnection;
  userCutsomers = [];
  selectedCustomerId = undefined;
  selectedCustomerName = '';

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: AuthService,
    private cookieService: CookieService,
    private userService: UserService,
    private router: Router,
    private toastrService: NbToastrService,
    private orderService: OrderService,
    private appGlobals: AppGlobals,
    private dialogService: NbDialogService,
  ) {


  }

  ngOnInit() {
    let custAuthInfo: any = this.cookieService.get('custAuthInfo');
    if (!custAuthInfo) {
      this.authService.logout();
    }
    let tokenDetails = JSON.parse(custAuthInfo);
    this.userId = tokenDetails.NameIdentifier;
    console.log('custAuthInfo', custAuthInfo);
    console.log('tokenDetails', tokenDetails);
    this.userService.getProfile(tokenDetails.NameIdentifier).subscribe((profileResponse: any) => {
      profileResponse.customerId = tokenDetails.CustomerId;
      this.selectedCustomerId = tokenDetails.CustomerId;
      this.cookieService.set('custInfo', JSON.stringify(profileResponse));
      var obj = profileResponse;

      obj.imageUrl = obj.imageUrl === null || obj.imageUrl === '' ?
        '' : this.appGlobals.S3.Bucket_URL + obj.imageUrl;
      obj.initials = (obj.firstName ? obj.firstName.charAt(0).toUpperCase() : '')
        + (obj.lastName ? obj.lastName.charAt(0).toUpperCase() : '');
      obj.name = obj.firstName + " " + obj.lastName;

      this.custInfo = obj;


      this.authService.setCustAuth();
      if (!profileResponse.isCustomerAdmin) {
        this.userMenu = this.userMenu.filter(m => m.title !== 'Invite User');
      }
    }, (err: any) => {
      this.toastrService.show('Error in loading profile.', 'Please Try again.', {
        status: 'warning'
      });
      //  this.authService.logout();


    });


    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemClick().subscribe((menu: any) => {
      if (menu.item && menu.item.type) {
        console.log('selected customer', menu.item);
        this.selectedCustomerId = menu.item.customerId;
        this.selectedCustomerName = menu.item.title;

        let custAuthInfo: any = this.cookieService.get('custAuthInfo');
        let tokenDetails = JSON.parse(custAuthInfo);
        tokenDetails.CustomerId = +menu.item.customerId;
        this.cookieService.set('custAuthInfo', JSON.stringify(tokenDetails));

        let custInfo: any = this.cookieService.get('custInfo');
        let custInfo_TokenDetails = JSON.parse(custInfo);
        custInfo_TokenDetails.customerId = +menu.item.customerId;
        this.cookieService.set('custInfo', JSON.stringify(custInfo_TokenDetails));

        this.userService.emitCustomerChange(menu.item.customerId);
      } else {
        if (menu.item && menu.item.title === 'Sign out') {
          this.authService.logout();
        } else if (menu.item && menu.item.title === 'Manage Account') {
          this.router.navigate(['/user/manage-account']);
        } else if (menu.item && menu.item.title === 'Settings') {
          this.router.navigate(['/user/settings']);
        } else if (menu.item && menu.item.title === 'Invite User') {
          this.router.navigate(['/user/invite']);
        } else if (menu.item && menu.item.title === 'Help & Support') {
          this.router.navigate(['/support']);
        }
      }

    });
    this.orderService.cartCount.subscribe((val: any) => {
      this.cartCount = val;
    });

    this.getPermission(tokenDetails.RoleId);
    this.getNotifications();
    this.startConnection();
    this.getUserCustomers();
    this.userService.getInputChangeNotificationReadImageEmitter().subscribe((response: any) => {
      this.getNotifications();
    });
  }

  getUserCustomers() {
    this.userService.getUserCustomers(this.userId).toPromise().then((res: any) => {
      console.log('getUserCustomers', res);

      this.userCutsomers = res.data;
      if (this.userCutsomers.length > 0) {
        this.userCutsomers.map((obj: any) => {
          obj.title = '(' + obj.nadNumber + ') ' + obj.customerName;
          obj.type = 'Customer';
        });

        this.selectedCustomerName = this.userCutsomers.find(t => t.customerId === +this.selectedCustomerId).title;
      } else {

      }
    });
  }

  onCustomerChage($event) {
    this.selectedCustomerId = $event;
    let custAuthInfo: any = this.cookieService.get('custAuthInfo');
    let tokenDetails = JSON.parse(custAuthInfo);
    tokenDetails.customerId = +$event;
    this.cookieService.set('custAuthInfo', JSON.stringify(tokenDetails));
    this.userService.emitCustomerChange($event);
  }

  getPermission(roleId) {
    this.userService.getPermissions(roleId).then((response: any) => {
      console.log('getPermission', response);
      if (response.data.length > 0) {
        const allPermissionsData: any = response.data;
        const parentPermission = allPermissionsData.filter(p => +p.parentId === 0);
        parentPermission.forEach(element => {
          const childPermission = allPermissionsData.filter(p => +p.parentId === +element.menuId);
          if (childPermission.length === 0 && element.isManage && element.isView
          ) {
            this.parentPermission.push(element.name);
          }

          childPermission.forEach(child => {
            if (child.isManage || child.isView) {
              this.childPermissions.push(element.name + '.' + child.name);
              if (this.parentPermission.indexOf(element.name) === -1) {
                this.parentPermission.push(element.name);
              }
            }
          });



        });

        if (this.childPermissions.indexOf(CUSTOMERMENU.Profile.Menu + '.' + CUSTOMERMENU.Profile.SubMenu.Settings) === -1) {
          this.userMenu = this.userMenu.filter(m => m.title !== 'Settings');
        }
        if (this.childPermissions.indexOf(CUSTOMERMENU.Profile.Menu + '.' + CUSTOMERMENU.Profile.SubMenu.ManageAccount) === -1) {
          this.userMenu = this.userMenu.filter(m => m.title !== 'Manage Account');
        }
        if (this.childPermissions.indexOf(CUSTOMERMENU.Profile.Menu + '.' + CUSTOMERMENU.Profile.SubMenu.HelpSupport) === -1) {
          this.userMenu = this.userMenu.filter(m => m.title !== 'Help & Support');
        }

        console.log('this.parentPermission', this.parentPermission);
        console.log('this.childPermissions', this.childPermissions);
      }
    });
  }


  toggleNotification() {
    this.showNotifications = (this.showNotifications == true) ? false : true;
    this.menuService.collapseAll();
  }

  onExpandMenu(isShowUserProfile = true) {
    this.showNotifications = false;
    if (isShowUserProfile) {
      setTimeout(() => {
        let menuItem = jQuery('.context-menu').find('.menu-items').first();
        let existItem = jQuery('.custom-menu-img');
        // jQuery('.menu-item:last-child').css({ "border-top": "1px solid #979797;" });
        let imageHtml = "";
        if (this.custInfo.imageUrl) {
          imageHtml = `<img src="${this.custInfo?.imageUrl}" alt="user-image" class="user-initials-dd rounded-circle-dd">`;
        } else {
          imageHtml = `<span class="user-initials-dd" id="profileImage">${this.custInfo?.initials}</span>`;
        }
        if (menuItem && existItem.length == 0) {
          jQuery(menuItem).prepend(`<li class="menu-item ng-tns-c118-7 ng-star-inserted custom-menu-img">
                      <div class="davt-cmt pt-3 pb-3" >`
            + imageHtml +
            `<span style="margin:7px;">${this.custInfo.firstName} ${this.custInfo.lastName}</span>
                        <div class="dpostcmtdate">${this.custInfo.email}</div>
                      </div>
                      </li>`);
        }
      }, 5);
    } else {
      // let div1: any = document.querySelector(".menu-item:last-child");
      // let div: any = jQuery('.context-menu').find('.menu-items').last();
      // // One property
      // // div.style.display = "block";
      // // Multiple properties
      // div.style.cssText = "border-top:none !important; color:red";
      // Multiple properties
      // div.setAttribute("style", "display:block; color:red");
      // jQuery('.menu-item:last-child').css({ 'border-top': 'none !important' });
      // jQuery('.menu-item').css("border-bottom", "1px solid #edf1f7 !important");
    }
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  onGoToCart() {
    let el = document.getElementById('cartscroll');
    if (el) {
      el.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }

  ngAfterViewInit() {

  }

  getNotifications() {
    console.log('getNotifications');
    const custAuthInfo: any = this.cookieService.get('custAuthInfo');

    const tokenDetails = JSON.parse(custAuthInfo);
    this.userService.getNotifications(tokenDetails.NameIdentifier).subscribe((response: any) => {
      console.log('getNotifications', response);
    });


    this.userService.getNotifications(tokenDetails.NameIdentifier, 'unread').subscribe((response: any) => {
      console.log('getCustomerOrderNotifications - response', response);
      this.notificationItems = [];
      this.notificationsList = response.data.records;
      this.unreadCount = response.data.totalUnread;
      if (response.data.length > 0) {
        const length = this.notificationsList.length > 5 ? 5 : this.notificationsList.length;
        // const length = this.notificationsList.length;
        for (let index = 0; index < length; index++) {
          const element = this.notificationsList[index];
          this.notificationItems.push({
            text: element.message,
            icon: 'mdi mdi-comment-account-outline',
            bgColor: 'danger',
            redirectTo: '/order/detail/' + element.keyId,
            createdDate: this.transform(element.creationDate),
            id: element.id,
            hasRead: element.hasRead,
            imageUrl: element.imageUrl === null || element.imageUrl === '' ?
              '' : this.appGlobals.S3.Bucket_URL + element.imageUrl,
            initials: element.firstName.charAt(0).toUpperCase() + element.lastName.charAt(0).toUpperCase(),
          });
        }
      } else {
        this.userService.getNotifications(tokenDetails.NameIdentifier).subscribe((allResponse: any) => {
          console.log('getCustomerOrderNotifications - response', response);
          this.notificationItems = [];
          this.notificationsList = allResponse.data.records;
          const length = this.notificationsList.length;
          for (let index = 0; index < length; index++) {
            const element = this.notificationsList[index];
            this.notificationItems.push({
              text: element.message,
              icon: 'mdi mdi-comment-account-outline',
              bgColor: 'primary',
              redirectTo: '/order/detail/' + element.keyId,
              createdDate: this.transform(element.creationDate),
              id: element.id,
              hasRead: element.hasRead,
              imageUrl: element.imageUrl === null || element.imageUrl === '' ?
                '' : this.appGlobals.S3.Bucket_URL + element.imageUrl,
              initials: element.firstName.charAt(0).toUpperCase() + element.lastName.charAt(0).toUpperCase(),
            });
          }
        });
      }
    });

  }

  redirectToOrderDetails(notification) {
    if (!notification.hasRead) {
      this.markNotificationsAsRead(notification);
    }
    this.router.navigate([notification.redirectTo]);
  }

  markNotificationsAsRead(notification) {
    this.userService.markNotificationAsRead(notification.id).then((response: any) => {
      this.getNotifications();
    });
  }

  transform(value: Date): string {
    const currDate = new Date();
    const diffMs = currDate.getTime() - new Date(value + 'Z').getTime();
    const sec = diffMs / 1000;
    if (sec === 0) {
      return 'Just now';
    }
    if (sec < 60) {
      return Math.round(sec) + ' second' + (Math.round(sec) > 1 ? 's' : '') + ' ago';
    }
    const min = sec / 60;
    if (min < 60) {
      return Math.round(min) + ' minute' + (Math.round(min) > 1 ? 's' : '') + ' ago';
    }
    const h = min / 60;
    if (h < 24) {
      return Math.round(h) + ' hour' + (Math.round(h) > 1 ? 's' : '') + ' ago';
    }
    const d = h / 24;
    return Math.round(d) + ' day' + (Math.round(d) > 1 ? 's' : '') + ' ago';
    // if (d < 7) {
    //    return Math.round(d) + ' day' + (Math.round(d) > 1 ? 's' : '') + ' ago';
    // } else {
    //     return this.datePipe.transform(value, 'MM/dd/yyyy');
    // }
  }


  public startConnection = () => {

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.SignalR_BaseUrl + 'notification')
      .build();

    this.hubConnection
      .start()
      .then((respons) => {
        console.log('Connection started');
        this.broadcastListener();
      })
      .catch(
        // err => console.log('Error while starting connection: ' + err)
        (err) => {
          console.log('Error while starting connection: ' + err);
          setTimeout(function () {
            this.startConnection();
          }, 5); // Restart connection after 5 seconds.
          console.log('Error while starting connection: ' + err);
        });
  }

  public broadcastListener = (data?) => {
    this.hubConnection.on('Notification', (data: any) => {
      console.log('broadcastListener', data);
      if (data.userId === this.userId) {
        this.getNotifications();
      }

    });
  }

}
