import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authSubject = new Subject<any>();

  constructor(private cookieService: CookieService, private router: Router) { }

  checkUserLogin() {
    return this.cookieService.check('loggedIn');
  }

  setCustAuth() {
    this.authSubject.next(true);
  }

  getCustAuthInfo() {
    return this.authSubject.asObservable();
  }

  logout(isReturnUrl = true) {
    console.log('this.router.url', this.router.url);
    window.localStorage.clear();
    this.cookieService.deleteAll();
    if (isReturnUrl) {
      this.router.navigate(['/auth/login'], {
        queryParams: {
          returnurl: this.router.url
        }
      })

    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  removeUserLoginDetails() {
    window.localStorage.clear();
    this.cookieService.deleteAll();
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode.jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

}
