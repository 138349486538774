import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/shared/user.service';
import * as signalR from '@aspnet/signalr';
import { environment } from 'environments/environment';
import { AppGlobals } from '../../../app.globals';

@Component({
  selector: 'ngx-view-all-notifications',
  templateUrl: './view-all-notifications.component.html',
  styleUrls: ['./view-all-notifications.component.scss']
})
export class ViewAllNotificationsComponent implements OnInit {
  @Input() userId: any;
  @Output() hideModal = new EventEmitter<any>();
  notificationItems = [];
  private hubConnection: signalR.HubConnection;

  constructor(private userService: UserService, private router: Router,
    private appGlobals: AppGlobals) { }

  ngOnInit(): void {
    this.getAllNotifications();
  }

  getAllNotifications() {
    this.userService.getNotifications(this.userId).subscribe((response: any) => {
      console.log('getCustomerOrderNotifications - response', response);
      const notificationsList = response.data.records;
      this.notificationItems = [];
      for (let index = 0; index < notificationsList.length; index++) {
        const element = notificationsList[index];
        this.notificationItems.push({
          text: element.message,
          icon: 'mdi mdi-comment-account-outline',
          bgColor: 'primary',
          redirectTo: '/order/detail/' + element.keyId,
          createdDate: this.transform(element.creationDate),
          id: element.id,
          hasRead: element.hasRead,
          imageUrl: element.imageUrl === null || element.imageUrl === '' ?
            '' : this.appGlobals.S3.Bucket_URL + element.imageUrl,
          initials: element.firstName.charAt(0).toUpperCase() + element.lastName.charAt(0).toUpperCase(),
        });
      }
    });
  }

  transform(value: Date): string {
    const currDate = new Date();
    const diffMs = currDate.getTime() - new Date(value + 'Z').getTime();
    const sec = diffMs / 1000;
    if (sec === 0) {
      return 'Just now';
    }
    if (sec < 60) {
      return Math.round(sec) + ' second' + (Math.round(sec) > 1 ? 's' : '') + ' ago';
    }
    const min = sec / 60;
    if (min < 60) {
      return Math.round(min) + ' minute' + (Math.round(min) > 1 ? 's' : '') + ' ago';
    }
    const h = min / 60;
    if (h < 24) {
      return Math.round(h) + ' hour' + (Math.round(h) > 1 ? 's' : '') + ' ago';
    }
    const d = h / 24;
    return Math.round(d) + ' day' + (Math.round(d) > 1 ? 's' : '') + ' ago';
    // if (d < 7) {
    //    return Math.round(d) + ' day' + (Math.round(d) > 1 ? 's' : '') + ' ago';
    // } else {
    //     return this.datePipe.transform(value, 'MM/dd/yyyy');
    // }
  }

  redirectToOrderDetails(notification) {
    if (!notification.hasRead) {
      this.markNotificationsAsRead(notification);
    }
    this.hideModal.emit();
    this.router.navigate([notification.redirectTo]);
  }

  markNotificationsAsRead(notification) {
    this.userService.markNotificationAsRead(notification.id).then((response: any) => {
      // this.getNotifications();S
    });
  }

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.SignalR_BaseUrl + 'notification')
      .build();

    this.hubConnection
      .start()
      .then((respons) => {
        console.log('Connection started');
        this.broadcastListener();
      })
      .catch(
        // err => console.log('Error while starting connection: ' + err)
        (err) => {
          console.log('Error while starting connection: ' + err);
          setTimeout(function () {
            this.startConnection();
          }, 5); // Restart connection after 5 seconds.
          console.log('Error while starting connection: ' + err);
        });
  }

  public broadcastListener = (data?) => {
    this.hubConnection.on('Notification', (data: any) => {
      if (data.userId === this.userId) {
        this.getAllNotifications();
      }
    });
  }

}
