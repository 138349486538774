import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'ngx-auth-layout',
  styleUrls: ['./auth.layout.scss'],
  template: `
    <nb-layout windowMode>


      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>


    </nb-layout>
  `,
})
export class AuthLayoutComponent {


  isLoggedIn: any = false;
  constructor(private cookieService: CookieService) {
    this.isLoggedIn = this.cookieService.check('isLoggedIn');
  }

}
