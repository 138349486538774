<div class="main container-fluid over-lay-content">
    <ul class="menu-items pb-0" style="overflow-y: auto;">
        <li class="notify-title">Notifications
            <div style="float: right;padding-right: 5px;cursor: pointer;" (click)="hideModal.emit()">
                <i class="nb-close-circled" style="font-size: 1.5em;font-weight: bold;"></i>
            </div>
        </li>
        <li class="notify-menu" *ngFor="let notification of notificationItems;">
            <a style="cursor: pointer;" (click)="redirectToOrderDetails(notification)">
                <div class="notify-picture">
                    <img *ngIf="notification.imageUrl!==''" src="{{notification.imageUrl}}" alt="user-image"
                        class="user-initials-dd rounded-circle-dd">
                    <span *ngIf="notification.imageUrl===''" class="user-initials-dd"
                        id="profileImage">{{notification.initials}}</span>
                </div> {{notification.text}}
                <div class="notify-text">{{notification.createdDate}}</div>
                <!-- <div class="notify-time">{{notification.createdDate}}</div> -->
            </a>
        </li>
        <!-- <li class="notify-title"
            style="border-top: #979797 solid 1px;border-bottom: 0 !important;text-align: center;text-decoration: underline;margin-top: 12px;">
            <a class="" (click)="toggleNotification();showAllNotification = true">View All</a>
        </li> -->
        <!-- <li class="notify-menu">
          <a class="">
            <div class="notify-picture"> </div> New user registered
            <div class="notify-text">Lorem Ipsum is simply dummy text of the printing and industry.</div>
            <div class="notify-time">5 min ago</div>
          </a>
        </li>
        <li class="notify-menu">
          <a class="">
            <div class="notify-picture"> </div> New user registered
            <div class="notify-text">Lorem Ipsum is simply dummy text of the printing and industry.</div>
            <div class="notify-time">5 min ago</div>
          </a>
        </li>
        <li class="notify-menu">
          <a class="">
            <div class="notify-picture"> </div> New user registered
            <div class="notify-text">Lorem Ipsum is simply dummy text of the printing and industry.</div>
            <div class="notify-time">5 min ago</div>
          </a>
        </li> -->
    </ul>
</div>