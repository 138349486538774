<div class="doNotPrint header-container">
  <div class="logo-container">
    <a class="logo" [routerLink]="['/part/list']"><img src="assets/images/logo.svg"></a>
  </div>
  <ul class="header-left-menu">
    <li *ngIf="parentPermission.indexOf('part_list')>-1"><a [routerLink]="['/part/list']"
        routerLinkActive="active">PARTS LIST</a></li>
    <li *ngIf="parentPermission.indexOf('my_orders')>-1"><a [routerLink]="['/order/list']" routerLinkActive="active">MY
        ORDERS</a></li>
    <li *ngIf="parentPermission.indexOf('invoice')>-1"><a [routerLink]="['/invoice']"
        routerLinkActive="active">INVOICE</a></li>
  </ul>

  <!--<nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>-->
</div>

<div class="doNotPrint header-container">


  <span *ngIf="cartCount>0" class="mr-3 cart-mobile"><a [routerLink]="" (click)="onGoToCart()"><img
        src="assets/images/cart.png">
      <p class="notify-circle">{{cartCount}}</p>
    </a>
  </span>
  <div class="mr-5" *ngIf="selectedCustomerId && userCutsomers.length>1">
    <nb-actions size="small">
      <nb-action (click)="onExpandMenu(false)" [nbContextMenu]="userCutsomers" class="user-action user-action-a"
        *nbIsGranted="['view', 'user']">
        <span class="username" style="padding: 15px !important;">{{selectedCustomerName}}</span>
        <img style="height:8px;" src="assets/images/downarrow.svg">
      </nb-action>
    </nb-actions>
  </div>
  <div class="dropdown mr-5">
    <span (click)="toggleNotification()"><img src="assets/images/bell.svg">
      <p *ngIf="unreadCount !== 0" class="notify-circle">{{unreadCount}}</p>
    </span>
    <div class="dropdown-content" *ngIf="showNotifications">
      <ul class="menu-items notification-main pb-0" style="overflow-y: auto;max-height: 650px;">
        <li class="notify-title">Notifications </li>
        <li class="notify-menu" *ngFor="let notification of notificationItems;">
          <a class="" (click)="toggleNotification();redirectToOrderDetails(notification)">
            <div class="notify-picture">
              <img *ngIf="notification.imageUrl!==''" src="{{notification.imageUrl}}" alt="user-image"
                class="user-initials-dd rounded-circle-dd">
              <span *ngIf="notification.imageUrl===''" class="user-initials-dd"
                id="profileImage">{{notification.initials}}</span>
            </div> {{notification.text}}
            <div class="notify-text">{{notification.createdDate}}</div>
            <!-- <div class="notify-time">{{notification.createdDate}}</div> -->
          </a>
        </li>
        <li class="notify-title"
          style="border-top: #979797 solid 1px;border-bottom: 0 !important;text-align: center;text-decoration: underline;margin-top: 12px;">
          <a class="" (click)="toggleNotification();showAllNotification = true">View All</a>
        </li>
        <!-- <li class="notify-menu">
          <a class="">
            <div class="notify-picture"> </div> New user registered
            <div class="notify-text">Lorem Ipsum is simply dummy text of the printing and industry.</div>
            <div class="notify-time">5 min ago</div>
          </a>
        </li>
        <li class="notify-menu">
          <a class="">
            <div class="notify-picture"> </div> New user registered
            <div class="notify-text">Lorem Ipsum is simply dummy text of the printing and industry.</div>
            <div class="notify-time">5 min ago</div>
          </a>
        </li>
        <li class="notify-menu">
          <a class="">
            <div class="notify-picture"> </div> New user registered
            <div class="notify-text">Lorem Ipsum is simply dummy text of the printing and industry.</div>
            <div class="notify-time">5 min ago</div>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
  <nb-actions size="small">
    <nb-action (click)="onExpandMenu()" [nbContextMenu]="userMenu" class="user-action user-action-a"
      *nbIsGranted="['view', 'user']">
      <img *ngIf="custInfo?.imageUrl" src="{{custInfo?.imageUrl}}" alt="user-image"
        class="user-initials rounded-circle">
      <span class="user-initials" *ngIf="!custInfo?.imageUrl" id="profileImage">
        {{custInfo?.initials}}</span>
      <span class="username">{{custInfo?.firstName}} {{custInfo?.lastName}}</span>
      <img style="height:8px;" src="assets/images/downarrow.svg">
    </nb-action>
  </nb-actions>
</div>

<ngx-view-all-notifications id="notificationOverLay" *ngIf="showAllNotification" [userId]="userId"
  (hideModal)="showAllNotification = !showAllNotification"
  class="doNotPrint overlay new-offsidebar fadeInRightBig animated transparent-back-ground">
</ngx-view-all-notifications>

<ng-template #viewAllNotifications let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Template Dialog</nb-card-header>
    <nb-card-body>Test</nb-card-body>
    <nb-card-footer>
      <button nbButton (click)="ref.close()">Close Dialog</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
<!-- <ng-template #viewAllNotifications let-data let-ref="dialogRef">
  <nb-layout windowMode>
    <nb-layout-column>
  <nb-card class="popupbox">
    <nb-card-header class="bbbot">Notifications
      <button nbButton (click)="ref.close()" class="mr-4 pop-right ">x</button>
    </nb-card-header>
    <nb-card-body>
    </nb-card-body>
  </nb-card>    
    </nb-layout-column>
  </nb-layout>
</ng-template> -->